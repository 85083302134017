import React from 'react';
import Field from './Field';

const Terminal = ({ theme, setTheme }) => {
	// const handleMinMax = () => {
	// 	setMaximized(!maximized)
	// 	document.querySelector('#field').focus()
	// }
	
	return <div id="terminal" style={theme.border}>
		<div style={theme.window}>
			{/* <button className="btn red" onClick={handleClose}/>
			<button id="useless-btn" className="btn yellow"/> */}
			{/* <button className="btn green" onClick={handleMinMax}/> */}
			{/* <span id="title" style={{color: theme.window.color}}>{title}</span> */}
		</div>
		<Field theme={theme} setTheme={setTheme}/>
	</div>
}

export default Terminal