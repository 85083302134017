export const WELCOME_THREAD =  [
    { text: 'Hi its Sachin', isPrimary: true },
    {
      text: 'What are you looking For ?',
      isPrimary: true,
      hasBuffer: true,
    },
    { text: 'Type help for commands', hasBuffer: true },
  ]

  export const VALID_COMMANDS =  [
    {
      command: 'help',
      purpose: 'Provides help information for React Terminal commands.',
    },
    {
      command: 'date',
      purpose: 'Displays the current date.',
    },
    {
      command: 'start',
      purpose: 'Launches a specified URL in a new tab or separate window.',
      help: [
        'START <URL>',
        'Launches a specified URL in a new tab or separate window.',
        '',
        'URL......................The website you want to open.',
      ],
    },
    {
      command: 'clear',
      purpose: 'Clears the screen.',
    },
    {
      command: 'theme',
      purpose: 'Sets the color scheme of the React Terminal.',
      help: [
        'THEME <L|LIGHT|D|DARK> [-s, -save]',
        'Sets the color scheme of the React Terminal.',
        '',
        'L, LIGHT.................Sets the color scheme to light mode.',
        'D, DARK..................Sets the color scheme to dark mode.',
        '',
        '-s, -save................Saves the setting to localStorage.',
      ],
    },
    {
      command: 'bio',
      component: 'AboutMe',
      purpose: 'Displays basic information about Sachin.',
    },
    {
      command: 'exit',
      purpose:
        "Quits the React Terminal and returns to Sachin's portfolio page.",
    },
    {
      command: 'time',
      purpose: 'Displays the current time.',
    },
    {
      command: 'about',
      isMain: true,
      purpose: 'Displays basic information about Sachin.',
    },
    {
      command: 'experience',
      isMain: true,
      purpose: "Displays information about Sachin's experience.",
    },
    {
      command: 'skills',
      isMain: true,
      purpose: "Displays information about Sachin's skills as a developer.",
    },
    {
      command: 'contact',
      isMain: true,
      purpose: 'Displays contact information for Sachin.',
    },
    {
      command: 'projects',
      isMain: true,
      purpose:
        'Displays information about what projects Sachin has done in the past.',
    },
    ...['google', 'duckduckgo', 'bing'].map((cmd) => {
      const properCase =
        cmd === 'google'
          ? 'Google'
          : cmd === 'duckduckgo'
          ? 'DuckDuckGo'
          : 'Bing'

      return {
        command: cmd,
        purpose: `Searches a given query using ${properCase}.`,
        help: [
          `${cmd.toUpperCase()} <QUERY>`,
          `Searches a given query using ${properCase}. If no query is provided, simply launches ${properCase}.`,
          '',
          `QUERY....................It's the same as if you were to type inside the ${properCase} search bar.`,
        ],
      }
    }),
  ]

  export const ABOUT_TEXT = [
    'Hey there!',
    `It's Sachin here. I'm a software engineer based in Galway, Ireland, specializing in the JavaScript ecosystem. I love programming and engineering interesting things for both regular folks and developers alike!`,
    `Type CONTACT if you'd like to get in touch - otherwise I hope you enjoy using the rest of the app!`,
  ]

export const EXPERIENCE =  [
    'Certificates:',
    'AWS Essentials  --  Udemy',
    '',
    'Work:',
    'Attinad Software',
    'Software Engineer',
    'October 2017 - September 2019',
    '',
    'Diagnal Technologies',
    'Senior Software Engineer',
    'Septermber 2019 - Dec 2020',
    '',
    'Cognizant',
    'Associate',
    'December 2020 - July 2021',
    '',
    'Iconic Engine',
    'Senior Software Engineer',
    'July 2021 - May 2022',
    '',
    '9th Impact',
    'Senior Software Engineer',
    'May 2022 - Present',
  ]

  export const SKILLS = [
    'Languages:',
    'JavaScript',
    'Node.js',
    'Basic Python',
    'SQL',
    'GraphQL',
    'HTML',
    'CSS',
    '',
    'Libraries/Frameworks:',
    'Express',
    'Loopback',
    'Sequelize',
    'Vue',
    'Basic React',
    '',
    'Technologies:',
    'DynamoDB',
    'MySQL',
    'Lambda',
    'Appsync',
    'Cloudformation',
    'Cognito',
    'AWS',
    'GCP',
    'Serverless',
    'ElasticSearch',
    'NoSQL',
    'Sockets',
  ]

  export const PROJECTS =  [
    '',
    'Portfolio',
    'Built with React',
    `Thought of breaking the monotonous nature of resumes`,
    '',
    'Order Confirmation Mail Parser',
    'Developed a stand-alone system capable of receiving order confirmation mails from popular vendors like Amazon and parsing them to store OrderID, Vendor and Customer info',
    'Project was based on Node.js, Sequelize, MySQL, Serverless, and AWS services like SES and Lambda',
    '',
    'Notification Module',
    'Developed a plug and play notification system capable of sending different types of notifications like templated emails, SMS, Whatsapp, and Push Notifications to a userbase from firebase or passed user data',
    'Project was based on Node.js, Serverless, CloudFunctions and Twilio APIs',
    '',
    'MetaData Scraper',
    'Developed and hosted a Metadata Scraper that scrapes a given website for Media Metadata tags',
    'Project was based on Node.js and leveraged libraries like Express and Cheerio',
  ]


  export const CONTACT = [
    'Email: sachinsreek@gmail.com',
    'Website: itssach.in',
    'LinkedIn: @sachin-sreekumar',
    'GitHub: @i-m-sac',
  ]