import React from 'react'
import { ABOUT_TEXT, CONTACT, EXPERIENCE, PROJECTS, SKILLS, VALID_COMMANDS, WELCOME_THREAD } from '../constants'
import AboutMe from './AboutMe'
let errCount = 0

class Field extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commandHistory: [],
      commandHistoryIndex: 0,
      fieldHistory: WELCOME_THREAD,
      userInput: '',
      isMobile: false,
    }
    this.recognizedCommands = VALID_COMMANDS
    this.handleTyping = this.handleTyping.bind(this)
    this.handleInputEvaluation = this.handleInputEvaluation.bind(this)
    this.handleInputExecution = this.handleInputExecution.bind(this)
    this.handleContextMenuPaste = this.handleContextMenuPaste.bind(this)
  }
  componentDidMount() {
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
      this.setState((state) => ({
        isMobile: true,
        fieldHistory: [
          ...state.fieldHistory,
          { isCommand: true },
          {
            text: `Due to this application being an 'input-less' environment, functionalities avaiable for mobile devices are limited. Please use the desktop version for all the functionalities! In the meantime, please use the helpers below`,
            isError: true,
            hasBuffer: true,
          },
        ],
      }))
    } else {
      const userElem = document.querySelector('#field')
      const themePref = window.localStorage.getItem('reactTerminalThemePref')

      // Disable this if you're working on a fork with auto run enabled... trust me.
      userElem.focus()

      // document.querySelector('#useless-btn').addEventListener('click', () => this.setState(state => ({
      // 	fieldHistory: [...state.fieldHistory, {isCommand: true}, {text: 'SYS >> That button doesn\'t do anything.', hasBuffer: true}]
      // })))

      if (themePref) {
        this.props.setTheme(themePref)
      }
    }
  }
  componentDidUpdate() {
    const userElem = document.querySelector('#field')

    userElem.scrollTop = userElem.scrollHeight
  }
  handleTyping(e) {
    e.preventDefault()

    const { key, ctrlKey, altKey } = e
    const forbidden = [
      ...Array.from({ length: 12 }, (x, y) => `F${y + 1}`),
      'ContextMenu',
      'Meta',
      'NumLock',
      'Shift',
      'Control',
      'Alt',
      'CapsLock',
      'Tab',
      'ScrollLock',
      'Pause',
      'Insert',
      'Home',
      'PageUp',
      'Delete',
      'End',
      'PageDown',
    ]

    if (!forbidden.some((s) => s === key) && !ctrlKey && !altKey) {
      if (key === 'Backspace') {
        this.setState({
          userInput: this.state.userInput.slice(0, -1),
        })
        // this.setState(state => state.userInput = state.userInput.slice(0, -1))
      } else if (key === 'Escape') {
        this.setState({ userInput: '' })
      } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
        const { commandHistory, commandHistoryIndex } = this.state
        const upperLimit = commandHistoryIndex >= commandHistory.length

        console.log({
          commandHistory,
          commandHistoryIndex,
          sc: this.state.commandHistory,
        })

        if (!upperLimit) {
          this.setState((state) => ({
            commandHistoryIndex: (state.commandHistoryIndex += 1),
            userInput: state.commandHistory[state.commandHistoryIndex - 1],
          }))
        }
      } else if (key === 'ArrowDown' || key === 'ArrowRight') {
        const { commandHistory, commandHistoryIndex } = this.state
        const lowerLimit = commandHistoryIndex === 0

        if (!lowerLimit) {
          this.setState((state) => ({
            commandHistoryIndex: (state.commandHistoryIndex -= 1),
            userInput:
              state.commandHistory[state.commandHistoryIndex - 1] || '',
          }))
        }
      } else if (key === 'Enter') {
        const { userInput } = this.state

        if (userInput.length) {
          this.setState(
            (state) => ({
              commandHistory:
                userInput === ''
                  ? state.commandHistory
                  : [userInput, ...state.commandHistory],
              commandHistoryIndex: 0,
              fieldHistory: [
                ...state.fieldHistory,
                { text: userInput, isCommand: true },
              ],
              userInput: '',
            }),
            () => this.handleInputEvaluation(userInput),
          )
        } else {
          this.setState((state) => ({
            fieldHistory: [...state.fieldHistory, { isCommand: true }],
          }))
        }
      } else {
        this.setState((state) => ({
          commandHistoryIndex: 0,
          userInput: state.userInput + key,
        }))
      }
    }
  }
  handleInputEvaluation(input) {
    try {
      const evaluatedForArithmetic = Math.evaluate(input)

      if (!isNaN(evaluatedForArithmetic)) {
        return this.setState((state) => ({
          fieldHistory: [
            ...state.fieldHistory,
            { text: evaluatedForArithmetic },
          ],
        }))
      }

      throw Error
    } catch (err) {
      console.log(err)
      const { recognizedCommands, giveError, handleInputExecution } = this
      const multiWordCommands = ['fuck that']
      const cleanedInput = input.toLowerCase().trim()
      const dividedInput = cleanedInput.split(' ')
      const parsedCmd = multiWordCommands.includes(cleanedInput)
        ? cleanedInput
        : dividedInput[0]
      const parsedParams = dividedInput.slice(1).filter((s) => s[0] !== '-')
      const parsedFlags = dividedInput.slice(1).filter((s) => s[0] === '-')
      const isError = !recognizedCommands.some((s) => s.command === parsedCmd)

      if (isError) {
        return this.setState((state) => ({
          fieldHistory: [...state.fieldHistory, giveError('nr', input)],
        }))
      }

      return handleInputExecution(parsedCmd, parsedParams, parsedFlags)
    }
  }
  handleInputExecution(cmd, params = [], flags = []) {
    if (cmd === 'help') {
      if (params.length) {
        if (params.length > 1) {
          return this.setState((state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              this.giveError('bp', { cmd: 'HELP', noAccepted: 1 }),
            ],
          }))
        }

        const cmdsWithHelp = this.recognizedCommands.filter((s) => s.help)

        if (cmdsWithHelp.filter((s) => s.command === params[0]).length) {
          return this.setState((state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              {
                text: cmdsWithHelp.filter((s) => s.command === params[0])[0]
                  .help,
                hasBuffer: true,
              },
            ],
          }))
        } else if (
          this.recognizedCommands.filter((s) => s.command === params[0]).length
        ) {
          return this.setState((state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              {
                text: [
                  `No additional help needed for ${this.recognizedCommands
                    .filter((s) => s.command === params[0])[0]
                    .command.toUpperCase()}`,
                  this.recognizedCommands.filter(
                    (s) => s.command === params[0],
                  )[0].purpose,
                ],
                hasBuffer: true,
              },
            ],
          }))
        }

        return this.setState((state) => ({
          fieldHistory: [
            ...state.fieldHistory,
            this.giveError('up', params[0].toUpperCase()),
          ],
        }))
      }

      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: [
              'Main commands:',
              ...this.recognizedCommands
                .sort((a, b) => a.command.localeCompare(b.command))
                .filter(({ isMain }) => isMain)
                .map(
                  ({ command, purpose }) =>
                    `${command.toUpperCase()}${Array.from(
                      { length: 15 - command.length },
                      (x) => '.',
                    ).join('')}${purpose}`,
                ),
              '',
              'All commands:',
              ...this.recognizedCommands
                .sort((a, b) => a.command.localeCompare(b.command))
                .map(
                  ({ command, purpose }) =>
                    `${command.toUpperCase()}${Array.from(
                      { length: 15 - command.length },
                      (x) => '.',
                    ).join('')}${purpose}`,
                ),
              '',
              'For help about a specific command, type HELP <CMD>, e.g. HELP PROJECT.',
            ],
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'clear') {
      return this.setState({ fieldHistory: [] })
    } else if (cmd === 'start') {
      if (params.length === 1) {
        return this.setState(
          (state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              { text: `Launching ${params[0]}...`, hasBuffer: true },
            ],
          }),
          () =>
            window.open(
              /http/i.test(params[0]) ? params[0] : `https://${params[0]}`,
            ),
        )
      }

      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          this.giveError('bp', { cmd: 'START', noAccepted: 1 }),
        ],
      }))
    } else if (cmd === 'date') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: `The current date is: ${new Date(
              Date.now(),
            ).toLocaleDateString()}`,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'cmd') {
      return this.setState(
        (state) => ({
          fieldHistory: [
            ...state.fieldHistory,
            {
              text: 'Launching new instance of the React Terminal...',
              hasBuffer: true,
            },
          ],
        }),
        () => window.open('itssach.in'),
      )
    } else if (cmd === 'theme') {
      const { setTheme } = this.props
      const validParams =
        params.length === 1 &&
        ['d', 'dark', 'l', 'light'].some((s) => s === params[0])
      const validFlags = flags.length
        ? flags.length === 1 && (flags[0] === '-s' || flags[0] === '-save')
          ? true
          : false
        : true

      if (validParams && validFlags) {
        const themeToSet =
          params[0] === 'd' || params[0] === 'dark' ? 'dark' : 'light'

        return this.setState(
          (state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              {
                text: `Set the theme to ${themeToSet.toUpperCase()} mode`,
                hasBuffer: true,
              },
            ],
          }),
          () => {
            if (
              flags.length === 1 &&
              (flags[0] === '-s' || flags[0] === '-save')
            ) {
              window.localStorage.setItem('reactTerminalThemePref', themeToSet)
            }

            setTheme(themeToSet)
          },
        )
      }

      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          this.giveError(
            !validParams ? 'bp' : 'bf',
            !validParams ? { cmd: 'THEME', noAccepted: 1 } : 'THEME',
          ),
        ],
      }))
    } else if (cmd === 'exit') {
      return (window.location.href = 'https://github.com/i-m-sac')
    } else if (cmd === 'time') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: `The current time is: ${new Date(
              Date.now(),
            ).toLocaleTimeString()}`,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'about') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text:  ABOUT_TEXT,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'bio') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            hasBuffer: true,
            isDiv: true,
            component: 'AboutMe',
          },
        ],
      }))
    } else if (cmd === 'experience') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: EXPERIENCE,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'skills') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: SKILLS,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'contact') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: CONTACT,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'projects') {
      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          {
            text: PROJECTS,
            hasBuffer: true,
          },
        ],
      }))
    } else if (cmd === 'project') {
      if (params.length === 1) {
        const projects = [
          {
            title: 'portfolio',
            live: 'https://itssach.in',
          },
        ]

        return this.setState(
          (state) => ({
            fieldHistory: [
              ...state.fieldHistory,
              { text: `Launching ${params[0]}...`, hasBuffer: true },
            ],
          }),
          () =>
            window.open(projects.filter((s) => s.title === params[0])[0].live),
        )
      }

      return this.setState((state) => ({
        fieldHistory: [
          ...state.fieldHistory,
          this.giveError('bp', { cmd: 'PROJECT', noAccepted: 1 }),
        ],
      }))
    } else if (['google', 'duckduckgo', 'bing'].some((s) => s === cmd)) {
      return this.setState(
        (state) => ({
          fieldHistory: [
            ...state.fieldHistory,
            {
              text: params.length
                ? `Searching ${cmd.toUpperCase()} for ${params.join(' ')}...`
                : `Launching ${cmd.toUpperCase()}...`,
              hasBuffer: true,
            },
          ],
        }),
        () =>
          window.open(
            params.length
              ? `https://${cmd}.com/${
                  cmd === 'google' ? 'search' : ''
                }?q=${params.join('+')}`
              : `https://${cmd}.com/`,
            '_blank',
          ),
      )
    }
  }
  handleContextMenuPaste(e) {
    e.preventDefault()

    if ('clipboard' in navigator) {
      navigator.clipboard.readText().then((clipboard) =>
        this.setState((state) => ({
          userInput: `${state.userInput}${clipboard}`,
        })),
      )
    }
  }
  giveError(type, extra) {
    const err = { text: '', isError: true, hasBuffer: true }

    if (type === 'nr') {
      if (errCount >= 2 && errCount < 4) {
        err.text = `Go home you're drunk. Either get or type help!`
        errCount = 0
      } else {
        errCount++
        err.text = `${extra} : The term or expression '${extra}' is not recognized. Check the spelling and try again. If you don't know what commands are recognized, type HELP.`
      }
    } else if (type === 'nf') {
      err.text = `The ${extra} command requires the use of flags. If you don't know what flags can be used, type HELP ${extra}.`
    } else if (type === 'bf') {
      err.text = `The flags you provided for ${extra} are not valid. If you don't know what flags can be used, type HELP ${extra}.`
    } else if (type === 'bp') {
      err.text = `The ${extra.cmd} command requires ${extra.noAccepted} parameter(s). If you don't know what parameter(s) to use, type HELP ${extra.cmd}.`
    } else if (type === 'up') {
      err.text = `The command ${extra} is not supported by the HELP utility.`
    }

    return err
  }

  resOnClickHandler = (label) => {
    let userInput = label

    if (userInput.length) {
      this.setState(
        (state) => ({
          commandHistory:
            userInput === ''
              ? state.commandHistory
              : [userInput, ...state.commandHistory],
          commandHistoryIndex: 0,
          fieldHistory: [
            ...state.fieldHistory,
            { text: userInput, isCommand: true },
          ],
          userInput: '',
        }),
        () => this.handleInputEvaluation(userInput),
      )
    } else {
      this.setState((state) => ({
        fieldHistory: [...state.fieldHistory, { isCommand: true }],
      }))
    }
  }

  renderComponents = (componentType) => {
    switch (componentType) {
      case 'AboutMe':
        return <AboutMe />

      default:
        return <div> Rendering a div </div>
    }
  }

  render() {
    const { theme } = this.props
    const { fieldHistory, userInput } = this.state

    return (
      <>
        <div
          id="field"
          className={theme.app.backgroundColor === '#333444' ? 'dark' : 'light'}
          style={theme.field}
          onKeyDown={(e) => this.handleTyping(e)}
          tabIndex={0}
          onContextMenu={(e) => this.handleContextMenuPaste(e)}
        >
          {fieldHistory.map(
            ({
              text,
              isCommand,
              isError,
              hasBuffer,
              isPrimary,
              isDiv,
              component,
            }) => {
              if (Array.isArray(text)) {
                return (
                  <MultiText
                    input={text}
                    isError={isError}
                    isPrimary={isPrimary}
                    hasBuffer={hasBuffer}
                  />
                )
              }
              if (isDiv) {
                return this.renderComponents(component)
              } else {
                return (
                  <Text
                    input={text}
                    isCommand={isCommand}
                    isError={isError}
                    isPrimary={isPrimary}
                    hasBuffer={hasBuffer}
                  />
                )
              }
            },
          )}
          <UserText input={userInput} theme={theme.cursor} />
        </div>
        <ul className="res-menu" style={theme.field}>
          <li onClick={() => this.resOnClickHandler('about')}>About</li>
          <li> | </li>
          <li onClick={() => this.resOnClickHandler('contact')}>Contact</li>
          <li> | </li>
          <li onClick={() => this.resOnClickHandler('Experience')}>
            Experience
          </li>
          <li> | </li>
          <li onClick={() => this.resOnClickHandler('Skills')}>Skills</li>
        </ul>
      </>
    )
  }
}

export default Field

const Text = ({ input, isCommand, isError, hasBuffer, isPrimary }) => (
  <>
    <div className={isPrimary ? 'highlight-terminal-text' : ''}>
      {isCommand && <div id="query">Guest@itssach.in:~</div>}
      <span className={!isCommand && isError ? 'error' : ''}>{input}</span>
    </div>
    {hasBuffer && <div></div>}
  </>
)
const MultiText = ({ input, isError, hasBuffer, isPrimary }) => (
  <>
    {input.map((s) => (
      <Text input={s} isPrimary={isPrimary} isError={isError} />
    ))}
    {hasBuffer && <div></div>}
  </>
)
const UserText = ({ input, theme }) => (
  <div>
    <div id="query">Guest@itssach.in:~</div>
    <span>{input}</span>
    <div id="cursor" style={theme}></div>
  </div>
)