import React, {useEffect} from 'react';
import Terminal from "./Terminal";


const TerminalWithRick = ({ theme, setTheme }) => {
  useEffect(() => {
    document.addEventListener('mousemove', handleEyeRoll)
  
    return () => {
      document.removeEventListener('mousemove', handleEyeRoll)
    }
  }, [])


  const handleEyeRoll = (e) => {
    const mouseX = e.clientX
    const mouseY = e.clientY
    const anchor = document.getElementById('rick')
    const rekt = anchor.getBoundingClientRect()
    const anchorX = rekt.left + rekt.width/2
    const anchorY = (rekt.top + rekt.height/2) - 20

    const angleDeg = angle(mouseX, mouseY, anchorX, anchorY)
    const eyes = document.querySelectorAll('.eye')
    eyes.forEach(eye => {
      eye.style.transform = `rotate(${90 + angleDeg}deg)`
    })

  }

  const angle = (cx, cy, ex, ey) => {
    const dy = cy -ey
    const dx = cx - ex
    const rad = Math.atan2(dy, dx)
    const deg = rad * 180 / Math.PI
    return deg
  }
  
  
  return <>
  <div className="rick-cont">
      <img id='rick' src="rick_vec.png" alt="" />
      <div id='eyes'>
        <img className='eye' src="eye_r1.png" alt="" style={ {top: '-147px', left: '295px'}} height="50px"/>
        <img className='eye' src="eye_r1.png" alt="" style={{top: '-147px', left: '330px'}}  height="50px"/>
      </div>
    </div>
    <Terminal theme={theme} setTheme={setTheme} />
  </>
}

export default TerminalWithRick