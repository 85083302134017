import React from 'react';
import './App.css';
import Terminal from './components/TerminalWithRick';
import Header from './components/header/header.component'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
const App = () => {
  const [theme, setTheme] = React.useState('dark')
  const themeVars = theme === 'dark' ? {
    app: { backgroundColor: '#090909' },
    terminal: { boxShadow: '0 2px 5px #111' },
    field: { backgroundColor: '#090909', color: '#F4F4F4', fontWeight: 'normal' },
    cursor: { animation: '1.02s blink-dark step-end infinite' },
    border: { border: 'solid white 2px' }
  } : {
      app: { backgroundColor: 'white' },
      terminal: { boxShadow: 'none' },
      window: { backgroundColor: '#5F5C6D', color: '#E3E3E3' },
      field: { backgroundColor: 'white', color: '#474554', fontWeight: 'bold' },
      cursor: { animation: '1.02s blink-light step-end infinite' },
      border: { border: 'solid black 2px' }
    }
  return <Router>
  <div style={themeVars.app} className='main-wrapper'>
    <Header theme={{ ...themeVars }} />
    <Switch>
      <Route path="/" exact>
        <div className='app-wrapper' theme={themeVars}>
          <div className='left'>
            <Terminal theme={themeVars} setTheme={setTheme} />
          </div>
        </div>
      </Route>
      <Route>
        <div className='app-wrapper notfound'>
          <img src='./404.jpg'/>
        </div>
      </Route>
    </Switch>
    <div className='footer'>
      <p style={themeVars.field}>© 2022 itssach.in</p>
    </div>
  </div>
  </Router>
}

export default App;