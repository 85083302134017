/**
 * @description About me component
 */
import React from "react";

const AboutMe = () => {
  const text = [
    "Hey there!",
    `It's Sachin here. I'm a software engineer based in Galway, Ireland, specializing in the JavaScript ecosystem. I love programming and engineering interesting things for both regular folks and developers alike!`,
    `Type CONTACT if you'd like to get in touch - otherwise I hope you enjoy using the rest of the app!`,
  ];

  const MultiText = ({ input, isError, hasBuffer, isPrimary }) => (
    <>
      {input.map((s) => (
        <Text input={s} isPrimary={isPrimary} isError={isError} />
      ))}
      {hasBuffer && <div></div>}
    </>
  );

  const Text = ({ input, isCommand, isError, hasBuffer, isPrimary }) => (
    <>
      <div className={isPrimary ? "highlight-terminal-text" : ""}>
        <span className={!isCommand && isError ? "error" : ""}>{input}</span>
      </div>
      {hasBuffer && <div></div>}
    </>
  );

  return (
    <div className="abouteme">
      <img src={"./sac.jpg"} alt="sac" />
      <div className="desc">
        <MultiText
          input={text}
          isError={false}
          isPrimary={false}
          hasBuffer={false}
        />
      </div>
    </div>
  );
};

export default AboutMe;
