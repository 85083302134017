import React from 'react'
import './header.styles.css'
// import { NavLink } from 'react-router-dom'

const Header = ({ theme }) => {
  return <>
    <div className="header">
      <div>
        <img src='./tag.png' alt="TAG" />
      </div>
    </div>
  </>
}

export default Header